<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
  SelectIcon,
  SelectTrigger,
  type SelectTriggerProps,
  useForwardProps,
} from "radix-vue";
import { ChevronDown } from "lucide-vue-next";
import { cn } from "@/lib/utils";

const props = defineProps<
  SelectTriggerProps & { class?: HTMLAttributes["class"] }
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <SelectTrigger
    v-bind="forwardedProps"
    :class="
      cn(
        // 'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
        'flex h-14 w-full items-center justify-between rounded-md border-input transition-colors transition-shadow bg-mono-off-white px-6 py-2 text font-semibold ring-offset-background placeholder:text-mono-light-grey placeholder:font-semibold focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 hover:bg-mono-white hover:shadow-inner focus-visible:bg-mono-white focus-visible:shadow-inner disabled:cursor-not-allowed disabled:text-mono-dark-silver disabled:bg-mono-white hover:disabled:bg-mono-white hover:disabled:shadow-none data-[state=open]:ring-ring data-[state=open]:ring-2 [&>.lucide-chevron-down-icon]:data-[state=open]:rotate-180',

        props.class,
      )
    "
  >
    <slot />
    <SelectIcon as-child>
      <font-awesome
        icon="lock"
        class="w-[20px] h-[20px] transition-all"
        v-if="props.disabled"
      />
      <ChevronDown class="w-[20px] h-[20px] transition-all" v-else />
    </SelectIcon>
  </SelectTrigger>
</template>
